define([
    'lodash',
    'santa-components',
    'react',
    'componentsCore',
    'stripSlideShow/common/slideNavigationMixin'
], function (_, santaComponents, React, componentsCore, slideNavigationMixin) {
    'use strict';

    const transitionGroup = React.createElement.bind(null, componentsCore.wixTransitionGroup);
    transitionGroup.type = componentsCore.wixTransitionGroup;

    const stateStripComponnent = {
        displayName: 'stateStrip',
        mixins: [slideNavigationMixin],
        propTypes: {
            animations: santaComponents.santaTypesDefinitions.animations,
            isSiteBusy: santaComponents.santaTypesDefinitions.isSiteBusy,
            updateColorInfo: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.updateInformation.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            screenSize: santaComponents.santaTypesDefinitions.screenSize.isRequired,
            areSlidesTransitionsAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isComponentTransitionAllowed.isRequired
        },
        isScreenWidth() { return true; },
        statics: {
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: {
                changeState: {methodName: 'moveToSlide', params: ['stateIndex']}
            }
        },
        getRootStyle(style) {
            return _.omit(style, 'height');
        },

        getTransitionParams() {
            return {
                width: this.props.screenSize.width
            };
        },

        getSkinProperties() {
            const stateToRender = this.getSlideToRender();
            return {
                '': {
                    className: this.classSet({'in-transition': this.state.isInTransition}),
                    role: 'region',
                    'aria-label': 'StateStrip',
                    style: {
                        height: ''
                    }
                },
                'inlineContent': {
                    'aria-live': 'polite',
                    parentConst: transitionGroup,
                    isSiteBusy: this.props.isSiteBusy,
                    transition: this.props.compProp.transition,
                    transitionDuration: this.props.areSlidesTransitionsAllowed ? this.getTransitionDuration() : 0,
                    transitionCallback: this.transitionCallback,
                    animations: this.props.animations,
                    getTransitionParams: this.getTransitionParams,
                    style: {
                        position: 'relative'
                    },
                    children: stateToRender ? [React.cloneElement(stateToRender, {
                        styleId: `${this.props.styleId}slide`,
                        rootStyle: {
                            position: 'relative',
                            width: '100%'
                        },                
                        inlineParentStyle: {
                            position: 'relative',
                            width: '100%'
                        },
                
                        inlineStyle: {position: 'relative'},
                        
                        onMouseEnter: this.onMouseEnter,
                        onMouseLeave: this.onMouseLeave
                    })] : []             
                }
            };
        }
    };


    return stateStripComponnent;
});
