define([
    'santa-components',
    'react',
    'componentsCore',
    'stripSlideShow/common/slideNavigationMixin'
], function (santaComponents, React, componentsCore, slideNavigationMixin) {
    'use strict';

    const transitionGroup = React.createElement.bind(null, componentsCore.wixTransitionGroup);
    transitionGroup.type = componentsCore.wixTransitionGroup;

    const stripSlideShowComponent = {
        displayName: 'stripSlideShow',
        mixins: [slideNavigationMixin],
        propTypes: {
            animations: santaComponents.santaTypesDefinitions.animations,
            isSiteBusy: santaComponents.santaTypesDefinitions.isSiteBusy,
            updateColorInfo: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.updateInformation.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            screenSize: santaComponents.santaTypesDefinitions.screenSize.isRequired,
            areSlidesTransitionsAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isComponentTransitionAllowed.isRequired
        },
        isScreenWidth() { return true; },
        statics: {
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: {
                nextSlide: {methodName: 'moveNextSlide', params: []},
                prevSlide: {methodName: 'movePreviousSlide', params: []},
                moveToSlide: {methodName: 'moveToSlide', params: ['slide']}
            }
        },
        getTransitionParams() {
            return {
                width: this.props.screenSize.width
            };
        },
        addSlideProps(slideToRender) {
            return [React.cloneElement(slideToRender, {
                onMouseEnter: this.onMouseEnter,
                onMouseLeave: this.onMouseLeave,
                flexibleBoxHeight: this.props.compProp.flexibleBoxHeight,
                shouldHideOverflowContent: this.props.compProp.shouldHideOverflowContent,
                parentId: this.props.id
            })];
        },

        getSkinProperties() {
            const slideToRender = this.getSlideToRender();
            return {
                '': {
                    role: 'region',
                    'aria-label': 'Slideshow',
                    onSwipeLeft: this.clickMoveToNextSlide,
                    onSwipeRight: this.clickMoveToPreviousSlide,
                    onKeyDown: this.keyboardInteractionHandler,
                    onFocus: this.stopAutoPlay,
                    onBlur: this.resumeAutoPlay
                },
                'inlineContentParent': {
                    className: this.classSet({'hide-overflow': this.props.compProp.shouldHideOverflowContent}),
                    tabIndex: -1
                },
                'inlineContent': {
                    'aria-live': 'polite',
                    parentConst: transitionGroup,
                    isSiteBusy: this.props.isSiteBusy,
                    transition: this.props.compProp.transition,
                    transitionDuration: this.props.areSlidesTransitionsAllowed ? this.getTransitionDuration() : 0,
                    transitionCallback: this.transitionCallback,
                    animations: this.props.animations,
                    reverse: this.isDirectionLeftToRight ? !this.reverse : this.reverse,
                    getTransitionParams: this.getTransitionParams,
                    children: this.addSlideProps(slideToRender)
                },
                'shownOnAllSlides': {
                    children: this.getShowOnAllSlidesComponents()
                },
                'navigationArrows': {
                    'data-show-navigation-arrows': this.props.compProp.showNavigationButton,
                    'data-navigation-button-margin': this.props.compProp.navigationButtonMargin,
                    style: this.getNavigationArrowsStyle()
                },
                'dotsMenuWrapper': {
                    'data-show-navigation-dots': this.props.compProp.showNavigationDots,
                    children: this.createDotsNavigationButtons(),
                    style: this.getDotsNavigationWrapperStyle()
                },
                'prevButton': {
                    onClick: this.clickMoveToPreviousSlide,
                    style: this.getArrowButtonStyle(true),
                    className: 'reset-button'
                },
                'nextButton': {
                    onClick: this.clickMoveToNextSlide,
                    style: this.getArrowButtonStyle(false),
                    className: 'reset-button'
                }
            };
        }
    };


    return stripSlideShowComponent;
});
