define([
    'lodash',
    'react',
    'santa-components',
    'componentsCore',
    'stripSlideShow/common/slideNavigationMixin'
], function (_, React, santaComponents, componentsCore, slideNavigationMixin) {
    'use strict';

    const transitionGroup = React.createElement.bind(null, componentsCore.wixTransitionGroup);
    transitionGroup.type = componentsCore.wixTransitionGroup;

    return {
        displayName: 'stateBox',
        mixins: [slideNavigationMixin],
        propTypes: {
            shouldResetComponent: santaComponents.santaTypesDefinitions.RenderFlags.shouldResetComponent,
            animations: santaComponents.santaTypesDefinitions.animations,
            updateColorInfo: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.updateInformation.isRequired,
            isSiteBusy: santaComponents.santaTypesDefinitions.isSiteBusy,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            areSlidesTransitionsAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isComponentTransitionAllowed.isRequired
        },
        statics: {
            behaviors: {
                changeState: {methodName: 'moveToSlide', params: ['stateIndex']}
            }
        },

        componentDidUpdate(prevProps) {
            if (this.props.shouldResetComponent !== prevProps.shouldResetComponent && this.state.currentIndex) {
                this.moveToSlide(0);
            }
        },

        getRootStyle(style) {
            return _.omit(style, 'height');
        },

        getDefaultSkinName() {
            return 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer';
        },

        getSkinProperties() {
            const stateToRender = this.getSlideToRender();
            return {
                '': {
                    'data-currentslide': _.get(stateToRender, 'props.id', ''),
                    className: this.classSet({'in-transition': this.state.isInTransition}),
                    style: {
                        height: 'auto'
                    }
                },
                'inlineContent': {
                    isSiteBusy: this.props.isSiteBusy,
                    parentConst: transitionGroup,
                    transition: this.props.compProp.transition,
                    transitionDuration: this.props.areSlidesTransitionsAllowed ? this.getTransitionDuration() : 0,
                    transitionCallback: this.transitionCallback,
                    animations: this.props.animations,
                    reverse: this.isDirectionLeftToRight ? !this.reverse : this.reverse,
                    style: {
                        position: 'relative'
                    },
                    children: stateToRender ? [React.cloneElement(stateToRender, {
                        styleId: `${this.props.styleId}slide`,
                        rootStyle: {
                            position: 'relative',
                            top: 0,
                            width: '100%'
                        },                
                        inlineParentStyle: {
                            position: 'relative'
                        },
                
                        inlineStyle: {position: 'relative'},
                        
                        onMouseEnter: this.onMouseEnter,
                        onMouseLeave: this.onMouseLeave
                    })] : []
                }
            };
        }
    };
});
