define([
    'santa-components',
    'stripSlideShow/common/stripSlideShow',
    'stripSlideShow/common/boxSlideShow',
    'stripSlideShow/common/stateBox',
    'stripSlideShow/common/stateStrip',
    'componentsCore',
    'skins',
    'stripSlideShow/skins/skins.json'
], function (
    santaComponents,
    stripSlideShowComponent,
    boxSlideShowComponent,
    stateBoxComponent,
    stateStripComponent,
    componentsCore,
    skinsPackage,
    skinsJson
) {
    'use strict';

    const stripSlideShow = {
        SlideShowSantaTypes: santaComponents.santaTypesDefinitions.SlideShow,
        boxSlideShow: boxSlideShowComponent,
        stateBox: stateBoxComponent,
        stateStrip: stateStripComponent,
        stripSlideShow: stripSlideShowComponent
    };

    componentsCore.compRegistrar
        .register('wysiwyg.viewer.components.BoxSlideShow', stripSlideShow.boxSlideShow)
        .register('wysiwyg.viewer.components.StripContainerSlideShow', stripSlideShow.stripSlideShow)
        .register('wysiwyg.viewer.components.StateStrip', stripSlideShow.stateStrip)
        .register('wysiwyg.viewer.components.StateBox', stripSlideShow.stateBox);

    skinsPackage.skinsMap.addBatch(skinsJson);

    return stripSlideShow;
});
