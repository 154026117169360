/**
 * Created by talm on 18/08/15.
 */
define(['santa-components'], function (santaComponents) {
    'use strict';

    /**
     * @class core.mixins.boxSlideShowAutoPlay
     */
    return {
        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            startAutoPlayInViewport: santaComponents.santaTypesDefinitions.SlideShow.startAutoPlayInViewport.isRequired,
            stopAutoPlayInViewport: santaComponents.santaTypesDefinitions.SlideShow.stopAutoPlayInViewport.isRequired
        },
        toggleAutoPlay() {
            this.setState({autoPlay: !this.state.autoPlay}, this.updateAutoPlayState);
        },
        updateAutoPlayState() {
            this.props.stopAutoPlayInViewport(this.timeoutId);
            if (this.state.autoPlay) {
                const interval = this.props.compProp.autoPlayInterval * 1000;
                this.timeoutId = this.props.startAutoPlayInViewport(this.clickMoveToNextSlide, interval);
            }
        },

        componentDidMount() {
            this.updateAutoPlayState();
        },

        componentWillUnmount() {
            this.props.stopAutoPlayInViewport(this.timeoutId);
        }
    };
});
