(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.common.components.boxSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "div",
        "prevButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 39 11",
            "enableBackground": "new 0 0 39 11"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "33.5,0 32.8,0.7 37.1,5 0,5 0,6 37.1,6 32.8,10.3 33.5,11 39,5.5 "
            }
          ]
        ]
      ],
      [
        "div",
        "nextButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 39 11",
            "enableBackground": "new 0 0 39 11"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "33.5,0 32.8,0.7 37.1,5 0,5 0,6 37.1,6 32.8,10.3 33.5,11 39,5.5 "
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "slide": {
      "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
      "styleGroup": "inherit"
    },
    "arrowWidthToHeightRatio": 0.3,
    "arrowWidthToSizeRatio": 0.7,
    "selectedButtonSizeRatio": 1.66
  },
  "params": {
    "shd": "BOX_SHADOW",
    "dotsColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "dotsColor": "color_12",
    "arrowColor": "color_12",
    "rd": "0"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;pointer-events:auto;position:relative;display:inline-block;cursor:pointer;border-radius:50%;background-color:[dotsColor];",
    "%prevButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:transparent;border:2px solid [dotsColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn:hover": "opacity:.6;",
    "%_btn svg": "fill:[arrowColor];"
  }
}
 skins['wysiwyg.common.components.boxSlideShow.viewer.skins.squareButtonsSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "div",
        "prevButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 50 50",
            "enableBackground": "new 0 0 50 50"
          },
          [
            "rect",
            null,
            [],
            {
              "width": "50",
              "height": "50"
            }
          ],
          [
            "path",
            null,
            [],
            {
              "d": "M28.5,32.4c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.5,0-0.7L22.2,25l6.7-6.5c0.2-0.2,0.2-0.5,0-0.7\n                \tc-0.2-0.2-0.5-0.2-0.7,0l-7.2,6.9c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.3,0.2,0.4l7.1,6.8C28.2,32.3,28.4,32.4,28.5,32.4z"
            }
          ]
        ]
      ],
      [
        "div",
        "nextButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 50 50",
            "enableBackground": "new 0 0 50 50"
          },
          [
            "rect",
            null,
            [],
            {
              "width": "50",
              "height": "50"
            }
          ],
          [
            "path",
            null,
            [],
            {
              "d": "M28.5,32.4c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.5,0-0.7L22.2,25l6.7-6.5c0.2-0.2,0.2-0.5,0-0.7\n                \tc-0.2-0.2-0.5-0.2-0.7,0l-7.2,6.9c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.3,0.2,0.4l7.1,6.8C28.2,32.3,28.4,32.4,28.5,32.4z"
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "slide": {
      "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
      "styleGroup": "inherit"
    },
    "arrowWidthToHeightRatio": 0.5,
    "arrowWidthToSizeRatio": 1,
    "selectedButtonSizeRatio": 1
  },
  "params": {
    "shd": "BOX_SHADOW",
    "dotsColor": "BG_COLOR_ALPHA",
    "dotsSelectedColor": "BG_COLOR_ALPHA",
    "arrowContainerColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "dotsColor": "color_12",
    "dotsSelectedColor": "color_11",
    "arrowContainerColor": "color_11",
    "arrowColor": "color_12",
    "rd": "0"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;pointer-events:auto;position:relative;display:inline-block;cursor:pointer;background-color:[dotsColor];",
    "%nextButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:[dotsSelectedColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn:hover": "opacity:.6;",
    "%_btn rect": "fill:[arrowContainerColor];",
    "%_btn path": "fill:[arrowColor];"
  }
}
 skins['wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "div",
        "prevButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ],
      [
        "div",
        "nextButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "slide": {
      "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
      "styleGroup": "inherit"
    },
    "arrowWidthToHeightRatio": 1,
    "arrowWidthToSizeRatio": 1,
    "selectedButtonSizeRatio": 1.66
  },
  "params": {
    "shd": "BOX_SHADOW",
    "dotsColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "dotsColor": "color_12",
    "arrowColor": "color_12",
    "rd": "0"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;pointer-events:auto;position:relative;display:inline-block;cursor:pointer;border-radius:50%;background-color:[dotsColor];",
    "%_btn:hover": "opacity:.6;",
    "%nextButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:transparent;border:2px solid [dotsColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn svg": "fill:[arrowColor];stroke:[arrowColor];stroke-width:1px;"
  }
}
 skins['wysiwyg.common.components.boxSlideShow.viewer.skins.thinArrowsSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "div",
        "prevButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ],
      [
        "div",
        "nextButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "slide": {
      "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
      "styleGroup": "inherit"
    },
    "arrowWidthToHeightRatio": 1,
    "arrowWidthToSizeRatio": 1,
    "selectedButtonSizeRatio": 1
  },
  "params": {
    "shd": "BOX_SHADOW",
    "dotsColor": "BG_COLOR_ALPHA",
    "dotsSelectedColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA",
    "rd": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "dotsColor": "color_12",
    "dotsSelectedColor": "color_11",
    "arrowColor": "color_12",
    "rd": "0"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;[shd]position:absolute;top:0;right:0;bottom:0;left:0;[rd]",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;pointer-events:auto;position:relative;display:inline-block;cursor:pointer;border-radius:50%;background-color:[dotsColor];",
    "%_btn:hover": "opacity:.6;",
    "%nextButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:[dotsSelectedColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn svg": "fill:[arrowColor];stroke:[arrowColor];stroke-width:1px;"
  }
}
 skins['wysiwyg.common.components.statebox.viewer.skins.StateBoxSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "exports": {
    "slide": {
      "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "rd": "0"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;[shd][rd]",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;position:relative;height:auto;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;",
    "%_in-transition": "overflow:hidden;",
    "*[data-leaving]": "position:absolute !important;"
  }
}
 skins['wysiwyg.common.components.statebox.viewer.skins.StateStripSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContent",
      [],
      {}
    ]
  ],
  "exports": {
    "slide": {
      "skin": "wysiwyg.common.components.boxSlideShowSlide.viewer.skins.boxSlideShowSlideSkin",
      "styleGroup": "inherit"
    }
  },
  "params": {
    "shd": "BOX_SHADOW",
    "rd": "BORDER_RADIUS"
  },
  "paramsDefaults": {
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "rd": "0"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;[shd][shd]  [rd]",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;position:relative;height:auto;width:100%;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;",
    "%_in-transition": "overflow:hidden;",
    "*[data-leaving]": "position:absolute !important;"
  }
}
 skins['wysiwyg.common.components.stripSlideShow.viewer.skins.longArrowsLargeSelectedCircleSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "div",
      "inlineContentParent",
      [],
      {},
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "div",
        "nextButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 39 11",
            "enableBackground": "new 0 0 39 11"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "33.5,0 32.8,0.7 37.1,5 0,5 0,6 37.1,6 32.8,10.3 33.5,11 39,5.5 "
            }
          ]
        ]
      ],
      [
        "div",
        "prevButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 39 11",
            "enableBackground": "new 0 0 39 11"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "33.5,0 32.8,0.7 37.1,5 0,5 0,6 37.1,6 32.8,10.3 33.5,11 39,5.5 "
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "arrowWidthToHeightRatio": 0.3,
    "arrowWidthToSizeRatio": 0.7,
    "selectedButtonSizeRatio": 1.66
  },
  "params": {
    "dotsColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "dotsColor": "color_12",
    "arrowColor": "color_12"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;border-radius:50%;background-color:[dotsColor];",
    "%prevButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:transparent;border:2px solid [dotsColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn:hover": "opacity:.6;",
    "%_btn svg": "fill:[arrowColor];"
  }
}
 skins['wysiwyg.common.components.stripSlideShow.viewer.skins.squareButtonsSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "div",
      "inlineContentParent",
      [],
      {},
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "div",
        "nextButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 50 50",
            "enableBackground": "new 0 0 50 50"
          },
          [
            "rect",
            null,
            [],
            {
              "width": "50",
              "height": "50"
            }
          ],
          [
            "path",
            null,
            [],
            {
              "d": "M28.5,32.4c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.5,0-0.7L22.2,25l6.7-6.5c0.2-0.2,0.2-0.5,0-0.7\n                \tc-0.2-0.2-0.5-0.2-0.7,0l-7.2,6.9c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.3,0.2,0.4l7.1,6.8C28.2,32.3,28.4,32.4,28.5,32.4z"
            }
          ]
        ]
      ],
      [
        "div",
        "prevButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 50 50",
            "enableBackground": "new 0 0 50 50"
          },
          [
            "rect",
            null,
            [],
            {
              "width": "50",
              "height": "50"
            }
          ],
          [
            "path",
            null,
            [],
            {
              "d": "M28.5,32.4c0.1,0,0.3-0.1,0.4-0.2c0.2-0.2,0.2-0.5,0-0.7L22.2,25l6.7-6.5c0.2-0.2,0.2-0.5,0-0.7\n                \tc-0.2-0.2-0.5-0.2-0.7,0l-7.2,6.9c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.1,0.1,0.3,0.2,0.4l7.1,6.8C28.2,32.3,28.4,32.4,28.5,32.4z"
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "arrowWidthToHeightRatio": 0.5,
    "arrowWidthToSizeRatio": 1,
    "selectedButtonSizeRatio": 1
  },
  "params": {
    "dotsColor": "BG_COLOR_ALPHA",
    "dotsSelectedColor": "BG_COLOR_ALPHA",
    "arrowContainerColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "dotsColor": "color_12",
    "dotsSelectedColor": "color_11",
    "arrowContainerColor": "color_11",
    "arrowColor": "color_12"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;background-color:[dotsColor];",
    "%nextButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:[dotsSelectedColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn:hover": "opacity:.6;",
    "%_btn rect": "fill:[arrowContainerColor];",
    "%_btn path": "fill:[arrowColor];"
  }
}
 skins['wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsLargeSelectedCircleSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "div",
      "inlineContentParent",
      [],
      {},
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "div",
        "nextButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ],
      [
        "div",
        "prevButton",
        [
          "_btn"
        ],
        {},
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "arrowWidthToHeightRatio": 1,
    "arrowWidthToSizeRatio": 1,
    "selectedButtonSizeRatio": 1.66
  },
  "params": {
    "dotsColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "dotsColor": "color_12",
    "arrowColor": "color_12"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;border-radius:50%;background-color:[dotsColor];",
    "%_btn:hover": "opacity:.6;",
    "%nextButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:transparent;border:2px solid [dotsColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn svg": "fill:[arrowColor];stroke:[arrowColor];stroke-width:1px;"
  }
}
 skins['wysiwyg.common.components.stripSlideShow.viewer.skins.thinArrowsSkin'] = {
  "react": [
    [
      "div",
      "bg",
      [],
      {}
    ],
    [
      "div",
      "inlineContentParent",
      [],
      {},
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ],
    [
      "div",
      "shownOnAllSlides",
      [],
      {}
    ],
    [
      "ol",
      "dotsMenuWrapper",
      [],
      {}
    ],
    [
      "div",
      "navigationArrows",
      [
        "_navigation-arrows"
      ],
      {},
      [
        "button",
        "nextButton",
        [
          "_btn"
        ],
        {
          "title": "next"
        },
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ],
      [
        "button",
        "prevButton",
        [
          "_btn"
        ],
        {
          "title": "previous"
        },
        [
          "svg",
          null,
          [],
          {
            "version": "1.1",
            "xmlns": "http://www.w3.org/2000/svg",
            "xmlnsXlink": "http://www.w3.org/1999/xlink",
            "x": "0px",
            "y": "0px",
            "viewBox": "0 0 21 41",
            "enableBackground": "new 0 0 21 41"
          },
          [
            "polygon",
            null,
            [],
            {
              "points": "20.3,40.8 0,20.5 20.3,0.2 21,0.9 1.3,20.5 21,40.1 "
            }
          ]
        ]
      ]
    ]
  ],
  "exports": {
    "arrowWidthToHeightRatio": 1,
    "arrowWidthToSizeRatio": 1,
    "selectedButtonSizeRatio": 1
  },
  "params": {
    "dotsColor": "BG_COLOR_ALPHA",
    "dotsSelectedColor": "BG_COLOR_ALPHA",
    "arrowColor": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "dotsColor": "color_12",
    "dotsSelectedColor": "color_11",
    "arrowColor": "color_12"
  },
  "css": {
    "%": "background-color:transparent;box-sizing:border-box !important;position:relative;min-height:50px;",
    "%[data-shouldhideoverflowcontent=\"true\"] %inlineContent": "overflow:hidden;",
    "%navigationArrows[data-show-navigation-arrows=\"false\"]": "display:none;",
    "%dotsMenuWrapper[data-show-navigation-dots=\"false\"]": "display:none;",
    "%bg": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent%_hide-overflow": "overflow:hidden;",
    "%dotsMenuWrapper": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;position:absolute;width:100%;pointer-events:none;",
    "%_navigation-arrows": "position:absolute;width:100%;",
    "%_navigation-dot": "pointer-events:auto;position:relative;display:inline-block;cursor:pointer;border-radius:50%;background-color:[dotsColor];",
    "%_btn:hover": "opacity:.6;",
    "%nextButton": "-webkit-transform:scaleX(-1);transform:scaleX(-1);",
    "%_navigation-dot%_selected": "background-color:[dotsSelectedColor];",
    "%_btn": "position:absolute;cursor:pointer;",
    "%_btn svg": "fill:[arrowColor];stroke:[arrowColor];stroke-width:1px;"
  }
}

        return skins;
    }));