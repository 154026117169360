define([
    'santa-components',
    'santa-core-utils',
    'lodash',
    'stripSlideShow/common/boxSlideShowAutoPlay',
    'componentsCore'
],
function (santaComponents, coreUtilsLib, _, boxSlideShowAutoPlay, componentsCore) {
    'use strict';

    const NAVIGATION_DOTS_FLEX_ALIGNMENT = {
        center: 'center',
        left: 'flex-start',
        right: 'flex-end'
    };
    const boxSlideShowCommon = componentsCore.utils.boxSlideShowCommon;

    function getNavigationDotsMaxSize(skinExports, navigationDotsSize) {
        const selectedButtonSizeRatio = skinExports.selectedButtonSizeRatio || 1;
        return Math.floor(navigationDotsSize * selectedButtonSizeRatio);
    }

    function getPublicState(state) {
        return {
            currentIndex: _.get(state, 'currentIndex', 0),
            isPlaying: _.get(state, 'autoPlay', false)
        };
    }

    /**
         * @class core.mixins.slideNavigationMixin
         */
    return {
        propTypes: {
            triggerFakeModeChange: santaComponents.santaTypesDefinitions.Modes.triggerFakeModeChange.isRequired,
            registerReLayoutPending: santaComponents.santaTypesDefinitions.Layout.registerReLayoutPending,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            canAutoPlay: santaComponents.santaTypesDefinitions.SlideShow.canAutoPlay.isRequired,
            slidesIndexes: santaComponents.santaTypesDefinitions.SlideShow.slidesIndexes.isRequired
        },
        mixins: [componentsCore.mixins.skinBasedComp, coreUtilsLib.timersMixins.timeoutsMixin, boxSlideShowAutoPlay, santaComponents.mixins.animationsMixin, santaComponents.mixins.compStateMixin(getPublicState)],
        getInitialState() {
            this.prevCompProps = _.cloneDeep(this.props.compProp);
            this.prevSlidesIndexes = _.cloneDeep(this.props.slidesIndexes);
            this.childrenOrder = _.cloneDeep(_.map(this.children, 'ref'));
            this.reverse = false;
            this.transitionFinishedCallback = _.noop;
            this.isDirectionLeftToRight = this.props.compProp.direction === 'LTR';
            return _.assign(getPublicState(null, {props: this.props.compProp}), {
                isInTransition: false,
                autoPlay: this.props.canAutoPlay
            });
        },
        setCurrentSlideAndRegisterRelayout(newSlideIndex, shouldForceSetState, shouldTransitionOccur, callback) {
            if (this.state.isInTransition && !shouldForceSetState) {
                return;
            }
            const slidesToLayout = _.at(this.props.children, [this.state.currentIndex, newSlideIndex]);
            _.forEach(slidesToLayout, slide => {
                if (slide) {
                    this.props.registerReLayoutPending(slide.props.id);
                }
            });
            this.registerReLayout();
            this.transitionFinishedCallback = _.isFunction(callback) ? callback : _.noop;
            this.setState({currentIndex: newSlideIndex, isInTransition: shouldTransitionOccur}, function () {
                this.props.triggerFakeModeChange(this.props.currentUrlPageId, this.props.id);
                this.handleAction('change');
            });
        },

        componentWillReceiveProps(nextProps) { // eslint-disable-line complexity
            if (this.prevCompProps.direction !== nextProps.compProp.direction) {
                this.isDirectionLeftToRight = nextProps.compProp.direction === 'LTR';
            }
            if (!nextProps.children) {
                return;
            }

            const currChildrenAmount = this.prevSlidesIndexes.length;
            const newChildrenAmount = nextProps.slidesIndexes.length;

            if (newChildrenAmount < currChildrenAmount) { //slide removed
                const nextChildrenOrder = _.map(nextProps.children, 'ref');
                const currSelected = this.childrenOrder[this.state.currentIndex];
                const diffBetweenChildren = _.difference(this.childrenOrder, nextChildrenOrder);
                const indexOfSlideThatWasRemoved = _.indexOf(this.childrenOrder, diffBetweenChildren[0]);

                let newIndex = this.state.currentIndex > 0 ? this.state.currentIndex - 1 : 0;
                if (_.includes(nextChildrenOrder, currSelected)) {
                    newIndex = nextChildrenOrder.indexOf(currSelected);
                } else if (newIndex >= newChildrenAmount) {
                    newIndex = newChildrenAmount - 1;
                }
                const isCurrentStateOutOfBounds = this.state.currentIndex >= newChildrenAmount;
                if (this.state.currentIndex !== newIndex) {
                    const isSelectedSlideRemoved = this.state.currentIndex === indexOfSlideThatWasRemoved;
                    this.setCurrentSlideAndRegisterRelayout(newIndex, isCurrentStateOutOfBounds, isSelectedSlideRemoved);
                }
            }

            if (nextProps.canAutoPlay !== this.state.autoPlay) {
                this.setState({autoPlay: nextProps.canAutoPlay}, this.setAutoPlayStateCallBack);
            }
            this.prevCompProps = _.cloneDeep(nextProps.compProp);
            this.prevSlidesIndexes = _.cloneDeep(nextProps.slidesIndexes);
            this.childrenOrder = _.cloneDeep(_.map(nextProps.children, 'ref'));
        },
        setAutoPlayStateCallBack() {
            this.handleAction(this.state.autoPlay ? 'autoplayOn' : 'autoplayOff');
            this.updateAutoPlayState();
        },
        createDotsNavigationButtons() {
            const skinExports = this.getSkinExports();

            const navigationDots = [];
            _.forEach(this.props.slidesIndexes, function (index) {
                const isSelected = this.state.currentIndex === index;
                const navigationDotsSize = isSelected ? getNavigationDotsMaxSize(skinExports, this.props.compProp.navigationDotsSize) :
                    this.props.compProp.navigationDotsSize;

                const params = {
                    className: `${this.classSet({
                        'navigation-dot': true,
                        'selected': isSelected
                    })} reset-button`,
                    style: {
                        width: navigationDotsSize,
                        height: navigationDotsSize,
                        marginRight: this.props.compProp.navigationDotsGap / 2,
                        marginLeft: this.props.compProp.navigationDotsGap / 2
                    },
                    'aria-label': `Go to slide ${index + 1}`
                };
                navigationDots.push(santaComponents.utils.createReactElement('li', {
                    className: this.classSet({
                        'navigation-dot-wrapper': true
                    }),
                    onClick: this.moveToSlide.bind(this, index),
                    children: santaComponents.utils.createReactElement('button', params)
                }));
            }.bind(this));

            return navigationDots;
        },
        getDotsNavigationWrapperStyle() {
            const skinExports = this.getSkinExports();
            const navigationDotsMaxSize = getNavigationDotsMaxSize(skinExports, this.props.compProp.navigationDotsSize);

            return {
                bottom: this.props.compProp.navigationDotsMargin - 0.5 * navigationDotsMaxSize, // eslint-disable-line no-mixed-operators
                justifyContent: this.getDotsAlignment(),
                WebkitJustifyContent: this.getDotsAlignment()
            };
        },
        moveNextSlide(callback) {
            if (this.state.isInTransition) {
                return;
            }

            this.reverse = false;
            const nextIndex = boxSlideShowCommon.getNextSlideIndex(this.props.slidesIndexes, this.state.currentIndex);
            this.setCurrentSlideAndRegisterRelayout(nextIndex, false, true, callback);
        },
        movePreviousSlide(callback) {
            if (this.state.isInTransition) {
                return;
            }


            this.reverse = true;
            const prevIndex = boxSlideShowCommon.getPrevSlideIndex(this.props.slidesIndexes, this.state.currentIndex);
            this.setCurrentSlideAndRegisterRelayout(prevIndex, false, true, callback);
        },
        isSlideDirectionReversed(newIndex) {
            const currIndex = this.state.currentIndex;
            if (newIndex > currIndex) {
                return currIndex === 0 && newIndex === this.props.slidesIndexes.length - 1;
            }
            return !(newIndex === 0 && currIndex === this.props.slidesIndexes.length - 1);
        },

        moveToSlide(index, callback) {
            if (index === this.state.currentIndex || this.state.isInTransition) {
                if (_.isFunction(callback)) {
                    callback();
                }
                return;
            }

            if (_.isNumber(index) && index >= 0 && index < this.props.slidesIndexes.length) {
                this.reverse = this.isSlideDirectionReversed(index);
                this.setCurrentSlideAndRegisterRelayout(index, false, true, callback);
            }
        },

        onMouseEnter() {
            if (this.state.autoPlay && this.props.compProp.pauseAutoPlayOnMouseOver) {
                this.setState({autoPlay: false}, this.updateAutoPlayState);
            }
        },
        onMouseLeave() {
            if (this.props.compProp.pauseAutoPlayOnMouseOver) {
                this.setState({autoPlay: this.props.canAutoPlay}, this.updateAutoPlayState);
            }
        },
        getTransitionDuration() {
            return this.props.compProp.transition === 'NoTransition' ? 0 : this.props.compProp.transDuration;
        },
        transitionCallback(callback) {
            this.setState({
                isInTransition: false
            }, () => {
                callback();
                this.transitionFinishedCallback();
                this.transitionFinishedCallback = _.noop;
            });
        },
        getArrowButtonStyle(isPrev) {
            const compProp = this.props.compProp;
            const skinExports = this.getSkinExports();
            const arrowWidthToHeightRatio = skinExports.arrowWidthToHeightRatio || 1;
            const arrowWidth = compProp.navigationButtonSize / arrowWidthToHeightRatio;

            const offset = compProp.navigationButtonMargin - 0.5 * arrowWidth; // eslint-disable-line no-mixed-operators
            const position = isPrev ? {left: offset} : {right: offset};

            return _.assign(position, {
                width: arrowWidth
            });
        },
        getNavigationArrowsStyle() {
            const skinExports = this.getSkinExports();
            const arrowWidthToSizeRatio = skinExports.arrowWidthToSizeRatio || 1;
            return {
                top: `calc(50% - ${this.props.compProp.navigationButtonSize * arrowWidthToSizeRatio}px)`
            };
        },
        getDotsAlignment() {
            return NAVIGATION_DOTS_FLEX_ALIGNMENT[this.props.compProp.navigationDotsAlignment];
        },

        clickMoveToNextSlide() {
            this.moveNextSlide();
        },

        clickMoveToPreviousSlide() {
            this.movePreviousSlide();
        },

        getSlideToRender() {
            return _.at(this.props.children, this.props.slidesIndexes)[this.state.currentIndex];
        },

        getShowOnAllSlidesComponents() {
            return _.reject(this.props.children, function (comp, i) {
                return _.includes(this.props.slidesIndexes, i);
            }.bind(this));
        },

        keyboardInteractionHandler(evt) {
            const specialKeys = ['ArrowRight', 'ArrowLeft', 'Home', 'End'];
            if (_.includes(specialKeys, evt.key)) {
                evt.preventDefault();

                if (this.refs.inlineContentParent.contains(window.document.activeElement)) {
                    this.refs.inlineContentParent.focus();
                }
            }
            if (evt.key === 'ArrowRight') {
                this.moveNextSlide();
            }
            if (evt.key === 'ArrowLeft') {
                this.movePreviousSlide();
            }
            if (evt.key === 'Home') {
                this.moveToSlide(0);
            }
            if (evt.key === 'End') {
                this.moveToSlide(this.getSlidesFromChildren(this.props.children).length - 1);
            }
        },

        stopAutoPlay() {
            this.setState({autoPlay: false});
        },

        resumeAutoPlay() {
            this.setState({autoPlay: this.props.canAutoPlay});
        }
    };
});
