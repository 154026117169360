define([
    'santa-components',
    'react',
    'componentsCore',
    'stripSlideShow/common/slideNavigationMixin'
], function (santaComponents, React, componentsCore, slideNavigationMixin) {
    'use strict';

    const transitionGroup = React.createElement.bind(null, componentsCore.wixTransitionGroup);
    transitionGroup.type = componentsCore.wixTransitionGroup;

    return {
        displayName: 'boxSlideShow',
        mixins: [slideNavigationMixin],
        propTypes: {
            animations: santaComponents.santaTypesDefinitions.animations,
            updateColorInfo: santaComponents.santaTypesDefinitions.VerticalAnchorsMenu.updateInformation.isRequired,
            isSiteBusy: santaComponents.santaTypesDefinitions.isSiteBusy,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            structure: santaComponents.santaTypesDefinitions.Component.structure.isRequired,
            areSlidesTransitionsAllowed: santaComponents.santaTypesDefinitions.RenderFlags.isComponentTransitionAllowed.isRequired
        },
        statics: {
            behaviors: {
                nextSlide: {methodName: 'moveNextSlide', params: []},
                prevSlide: {methodName: 'movePreviousSlide', params: []},
                moveToSlide: {methodName: 'moveToSlide', params: ['slide']}
            }
        },
        getSkinProperties() {
            return {
                '': {
                    'data-shouldhideoverflowcontent': this.props.compProp.shouldHideOverflowContent,
                    onSwipeLeft: this.clickMoveToNextSlide,
                    onSwipeRight: this.clickMoveToPreviousSlide
                },
                'inlineContent': {
                    isSiteBusy: this.props.isSiteBusy,
                    parentConst: transitionGroup,
                    transition: this.props.compProp.transition,
                    transitionDuration: this.props.areSlidesTransitionsAllowed ? this.getTransitionDuration() : 0,
                    transitionCallback: this.transitionCallback,
                    animations: this.props.animations,
                    reverse: this.isDirectionLeftToRight ? !this.reverse : this.reverse,
                    children: [React.cloneElement(this.getSlideToRender(), {
                        onMouseEnter: this.onMouseEnter,
                        onMouseLeave: this.onMouseLeave,
                        flexibleBoxHeight: this.props.compProp.flexibleBoxHeight,
                        shouldHideOverflowContent: this.props.compProp.shouldHideOverflowContent,
                        skin: this.getSkinExports().slide.skin,
                        styleId: `${this.props.styleId}slide`,
                        parentId: this.props.id
                    })]
                },
                'shownOnAllSlides': {
                    children: this.getShowOnAllSlidesComponents()
                },
                'navigationArrows': {
                    'data-show-navigation-arrows': this.props.compProp.showNavigationButton,
                    style: this.getNavigationArrowsStyle()
                },
                'dotsMenuWrapper': {
                    'data-show-navigation-dots': this.props.compProp.showNavigationDots,
                    children: this.createDotsNavigationButtons(),
                    style: this.getDotsNavigationWrapperStyle()
                },
                'prevButton': {
                    onClick: this.clickMoveToPreviousSlide,
                    style: this.getArrowButtonStyle(true)
                },
                'nextButton': {
                    onClick: this.clickMoveToNextSlide,
                    style: this.getArrowButtonStyle(false)
                }
            };
        }
    };
});
